import React from "react";
import { useDrop } from "react-dnd";

function DropZone({ day, hospital, onDrop, children }) {
  const [{ isOver }, drop] = useDrop(() => ({
    accept: "PERSON",
    drop: (person) => onDrop(person, day, hospital),
    collect: (monitor) => ({
      isOver: monitor.isOver(),
    }),
  }));

  return (
    <div
      ref={drop}
      style={{
        minHeight: "50px",
        backgroundColor: isOver ? "#f0f0f0" : "#fff",
        border: "1px dashed #ccc",
        padding: "5px",
      }}
    >
      {children}
    </div>
  );
}

export default DropZone;