import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid, IconButton,
  TextField
} from "@mui/material";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import axios from './services/api';
import {AdsClick, AdsClickTwoTone, Check, TouchApp} from "@mui/icons-material";
import AutoAwesomeOutlinedIcon from '@mui/icons-material/AutoAwesomeOutlined';

const DialogPersonInfo = ({selectedPerson, showDetails, handleCloseDetails}) => {

  const { t } = useTranslation();
  const [suggestionAi, setSuggestionAi] = useState(null);
  const [path, setPath] = useState(null);
  const [customPath, setCustomPath] = useState(null);
  const [nextSuggestions, setNextSuggestions] = useState(null);

  useEffect(() => {

    axios.get('/suggestion')
      .then(response => {
        setNextSuggestions(response.data);
      })
      .catch(error => {
        console.error(error);
      });

  }, []);

  const getAiSuggestion = () => {
    const request = {
      events: selectedPerson.visits.map(visit => visit.notes),
      people: selectedPerson,
      path: path || customPath,
      language: "pt-BR"
    }

    axios.post('/suggestion', request)
      .then(response => {
        setSuggestionAi(response.data);
      })
      .catch(error => {
        console.error(error);
      });
  }

  const closeDialog = () => {
    setPath(null);
    setCustomPath(null);
    setSuggestionAi(null);
    handleCloseDetails();
  }

  return (
    <Dialog open={showDetails} onClose={closeDialog}>
      <DialogTitle>{t('eventTable.eventDetails')}</DialogTitle>
      <DialogContent>
        {selectedPerson && (
          <>
            <DialogContentText><strong>{t('eventTable.doctorName')}: </strong>{selectedPerson.name}
            </DialogContentText>
            <DialogContentText><strong>{t('eventTable.area')}: </strong>{selectedPerson.area}
            </DialogContentText>
            <DialogContentText><strong>{t('eventTable.location')}: </strong>{selectedPerson.location}
            </DialogContentText>
            <DialogContentText>
              {selectedPerson.visits && selectedPerson.visits.length > 1 ? (
                <div>
                  <strong>{t('eventTable.products')}: </strong>{selectedPerson.visits[0].products.join(', ')}
                </div>
              ) : ( <div/> )}
            </DialogContentText>
            <DialogContentText style={{marginBottom: "10px"}}>
              {selectedPerson.visits && selectedPerson.visits.length > 1 ? (
                <div>
                  <strong>{t('eventTable.period')}: </strong>{selectedPerson.visits[0].period}
                </div>

              ) : ( <div/> )}
            </DialogContentText>
            {false && (
              <div style={{
                flexDirection: "column",
                marginBottom: "10px"
              }}>
                <p style={{color: "purple", fontStyle: "italic", fontSize: "0.8em", fontWeight : "bold", marginBottom: "5px"
                }}>{t('eventTable.useAIToEnhanceAndIdeas')}
                </p>
                <div style={{
                  display: "flex",
                  flexWrap: "wrap",
                  marginBottom: "10px"
                }}>
                  {nextSuggestions.map((obj, index) => (

                      <Button
                        key={index}
                        onClick={() => {
                          setPath(obj);
                          setCustomPath(null);
                          getAiSuggestion();
                        }}
                        fullWidth={false}
                        style={{
                          marginRight: "10px", marginBottom: "10px",
                          backgroundColor: path === obj ? "rebeccapurple" : "purple",
                          color: "wheat",
                          textTransform: "none", fontWeight: path === obj ? "bold" : "normal",
                          fontSize: "0.7em"
                      }}
                        color="primary">
                        {obj}
                      </Button>
                  ))}
                </div>

                <div style={{
                  display: "flex",
                  flexWrap: "wrap",
                  marginBottom: "10px",
                  width: "100%"
                }}>
                  <TextField
                    label={'Qual o objetivo da visita?'}
                    value={customPath}
                    onChange={(e) => {
                      setPath(null);
                      setCustomPath(e.target.value);
                    }}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') {
                        setPath(null);
                        getAiSuggestion();
                      }
                    }}
                    variant="outlined"
                    style={{
                      marginBottom: "10px",
                      backgroundColor: "transparent",
                      width: "80.5%",
                    }}
                    fullWidth={false}
                    multiline={false}
                  />

                  <Button
                    disabled={!customPath}
                    onClick={() => {
                      setPath(null);
                      getAiSuggestion();
                    }}
                    fullWidth={false}
                    style={{
                      marginBottom: "10px",
                      marginLeft: "10px",
                      backgroundColor: "rebeccapurple",
                      color: "wheat",
                      textTransform: "none", fontWeight: customPath ? "bold" : "normal",
                      width: "10%",
                    }}
                    color="primary"
                  >
                    <AutoAwesomeOutlinedIcon />
                  </Button>
                </div>

                {suggestionAi && (
                  <p style={{
                    //backgroundColor: "#f0f0f0",
                    padding: "0px",
                    marginTop: "0px",
                    marginBottom: "0px",
                    fontSize: "0.9em",
                    //borderRadius: "5px",
                    //border: "1px solid #ccc",
                    fontStyle: "italic"
                  }}>
                    {suggestionAi}
                  </p>
                )}
              </div>
            )}
            <DialogContentText>
              <div style={{ display: "flex", flexDirection: "column-reverse", alignItems: "center", position: "relative" }}>


                <div style={{ position: "absolute", left: "15px", top: 0, bottom: 0, width: "2px", backgroundColor: "#ccc" }}></div>
                {selectedPerson.visits.slice().reverse().map((obj, index) => (
                  <Grid
                    container
                    key={index}
                    style={{ display: "flex", alignItems: "flex-start", paddingLeft: "30px", marginBottom: "15px", position: "relative" }}
                  >
                    <div style={{
                      width: "10px",
                      height: "10px",
                      borderRadius: "50%",
                      backgroundColor: "#4caf50",
                      position: "absolute",
                      left: "11px",
                      top: "6px"
                    }}></div>
                    <Grid item>
                      <strong>{new Date(obj.visitDate).toLocaleDateString('pt-BR')}</strong>
                      <div style={{ marginBottom: "10px" }}>{obj.notes}</div>
                    </Grid>
                  </Grid>
                ))}
              </div>
            </DialogContentText>
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={closeDialog} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );

}

export default DialogPersonInfo;