import React, { useState } from "react";
import TableComponent from "./TableComponent";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import axios from './services/api';

function WeekPlan({ events, schedule, setSchedule }) {
  const handleDrop = (person, day, hospital) => {
    setSchedule((prev) => {
      const updatedSchedule = { ...prev };

      if (!updatedSchedule[day]) {
        updatedSchedule[day] = {};
      }
      if (!updatedSchedule[day][hospital]) {
        updatedSchedule[day][hospital] = [];
      }

      updatedSchedule[day][hospital] = [
        ...updatedSchedule[day][hospital],
        person,
      ];

      axios.post('/week-plan', updatedSchedule);

      return updatedSchedule;
    });
  };

  const removePerson = (day, hospital, person) => {
    setSchedule((prev) => {
      const updatedSchedule = { ...prev };
      updatedSchedule[day][hospital] = updatedSchedule[day][hospital].filter(
        (p) => p.name !== person.name
      );

      if (updatedSchedule[day][hospital].length === 0) {
        delete updatedSchedule[day][hospital];
      }
      if (Object.keys(updatedSchedule[day]).length === 0) {
        delete updatedSchedule[day];
      }

      axios.post('/week-plan', updatedSchedule);

      return updatedSchedule;
    });
  };

  const removeHospital = (hospital) => {
    setSchedule((prev) => {
      const updatedSchedule = { ...prev };
      Object.keys(updatedSchedule).forEach((day) => {
        if (updatedSchedule[day][hospital]) {
          delete updatedSchedule[day][hospital];
        }
        if (Object.keys(updatedSchedule[day]).length === 0) {
          delete updatedSchedule[day];
        }
      });
      axios.post('/week-plan', updatedSchedule);
      return updatedSchedule;
    });
  };

  const handleSavePlan = () => {
    axios.post('/week-plan', schedule);
  };

  return (
    <DndProvider backend={HTML5Backend}>
      <div style={{ marginBottom: "0px", display: "flex", justifyContent: "space-between" }}>
        {/*<h2>Weekly Plan</h2>*/}
        {/*<Button variant="contained" color="primary" onClick={handleSavePlan} style={{marginTop: "10px"}}>
          Save Plan
        </Button>*/}
      </div>
      <TableComponent
        schedule={schedule}
        onDrop={handleDrop}
        removePerson={removePerson}
        removeHospital={removeHospital}
      />
    </DndProvider>
  );
}

export default WeekPlan;