import React, {useState} from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle, Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import {Delete, Info} from "@mui/icons-material";
import DropZone from "./DropZone";
import {useTranslation} from "react-i18next";
import DialogPersonInfo from "./DialogPersonInfo";

const daysOfWeek = ["Segunda", "Terça", "Quarta", "Quinta", "Sexta"];


function TableComponent({ schedule, onDrop, removePerson, removeHospital }) {
  const { t } = useTranslation();
  const [selectedPerson, setSelectedPerson] = useState(null);
  const [showDetails, setShowDetails] = useState(false);

  const allHospitals = Object.keys(schedule).reduce((acc, day) => {
    Object.keys(schedule[day]).forEach((hospital) => acc.add(hospital));
    return acc;
  }, new Set());

  const rows = Array.from(allHospitals);

  rows.push(""); // Add one empty row

  const handleShowDetails = (person) => {
    console.log(person);
    setSelectedPerson(person);
    setShowDetails(true);
  };

  const handleCloseDetails = () => {
    setSelectedPerson(null);
    setShowDetails(false);
  };

  return (
    <TableContainer component={Paper} style={{marginLeft: "15px"}}>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Hospital</TableCell>
            {daysOfWeek.map((day) => (
              <TableCell key={day}>{day}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((hospital, rowIndex) => (
            <TableRow key={hospital || `empty-${rowIndex}`}>
              <TableCell style={{ padding: "6px 12px" }}>
                {hospital || "Drag here to add hospital"}
                {hospital && (
                  <IconButton
                    style={{float: "right"}}
                    aria-label="delete hospital"
                    onClick={() => removeHospital(hospital)}
                    size="small"
                  >
                    <Delete fontSize="small" />
                  </IconButton>
                )}
              </TableCell>
              {daysOfWeek.map((day) => (
                <TableCell key={day} style={{ padding: "6px 12px" }}>
                  <DropZone
                    day={day}
                    hospital={hospital || `empty-${rowIndex}`}
                    onDrop={(person) =>
                      onDrop(
                        person,
                        day,
                        hospital || person.location // Assign hospital if it was empty
                      )
                    }
                  >
                    {(schedule[day]?.[hospital] || []).map((person) => (
                      <div>
                        <div key={person.name} style={{
                          display: "flex",
                          alignItems: "center",
                          fontSize: "0.8em",
                          justifyContent: "space-between",
                          backgroundColor: person.potential === "BAIXO" ? "#fdedec"
                            : person.potential === "MEDIO" ? "#fbefde"
                              : "#e9f8e1",
                        }}>
                          <span>{`${person.name} - ${person.area}`}</span>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <IconButton
                              aria-label="person details"
                              size="small"
                              onClick={() => handleShowDetails(person)}
                            >
                              <Info fontSize="small" />
                            </IconButton>
                            <IconButton
                              aria-label="delete person"
                              size="small"
                              onClick={() => removePerson(day, hospital, person)}
                            >
                              <Delete fontSize="small" />
                            </IconButton>
                          </div>
                        </div>
                      </div>
                    ))}
                  </DropZone>
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>

      <DialogPersonInfo
        selectedPerson={selectedPerson}
        showDetails={showDetails}
        handleCloseDetails={handleCloseDetails}
      />

    </TableContainer>
  );
}

export default TableComponent;